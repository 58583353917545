'use strict';

angular.module('cpformplastApp')
	.service('Print', function($window) {

    this.printTable = function(title, tableStructure, tableContent) {
      var html = "<div>";
      html += "<h1 style='text-align: center'>" + title + "</h1>";
      html += "<table style='width:100%; text-align: center;'>";

      html += "<tr>";

      for (var i=0 ; i<tableStructure.length ; i++) {
        html += "<th style='border:1px solid black'>" + tableStructure[i].title + "</th>";
      }

      html += "</tr>";

      for (i=0 ; i<tableContent.length ; i++) {
        html += "<tr>";

        for (var j=0 ; j<tableStructure.length ; j++) {
          var content = (tableContent[i][tableStructure[j].value]) ? tableContent[i][tableStructure[j].value] : "";

          html += "<td style='border:1px solid black'>" + content + "</td>";
        }

        html += "</tr>";
      }

      html += "</table>"
      html += "</div>";

      var printWindow = $window.open('', '', 'width=800, height=600');
      $(printWindow.document.body).html(html);
      printWindow.print();
    };

});
